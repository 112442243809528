import FileUploader from './components/file-uploader';

function initializeFileUploaders() {
  $('.filepond').each(function(index) {
    let options,
        title = $(this).data('title'),
        uploadType = $(this).data('upload-type');
    options = {
      labelIdle: title,
      allowMultiple: uploadType === 'multiple'
    }
    new FileUploader(this, options);
  });
}

$(function () {
  initializeFileUploaders();
});

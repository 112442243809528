import Cookies from 'js-cookie';

// Main filepond JS and CSS
import { registerPlugin } from 'filepond';
import * as FilePond from 'filepond';
import 'filepond/dist/filepond.min.css';

// Image preview plugin JS and CSS
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// File size validation plugin
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

const DEFAULT_OPTIONS = {
  server: {
    process: {
      url: '/filepond/process/',
      headers : {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': Cookies.get('csrftoken')
      }
    },
    revert: {
      url: '/filepond/revert/',
      headers : {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': Cookies.get('csrftoken')
      }
    }
  },

  // Removes "Powered by PQINA" label
  credits: {},

  labelIdleSuffix: '<br>Drag & drop sau <span class="filepond--label-action">click aici</span>',
  labelFileProcessing: 'Se încarcă',
  labelFileProcessingComplete: 'Încărcat cu succes',
  labelTapToCancel: 'apasă pentru a opri',
  labelTapToRetry: 'apasă pentru a reîncerca',
  labelTapToUndo: 'apasă pentru a șterge',

  maxFileSize: '10MB',
  maxTotalFileSize: '100MB', // TODO: Should be 300MB for Pro
  labelMaxFileSizeExceeded: 'Fișierul este prea mare!',
  labelMaxFileSize: 'Dimensiunea maximă admisă este {filesize}',
  labelMaxTotalFileSizeExceeded: 'A fost depășită dimensiunea totală admisă!',
  labelMaxTotalFileSize: 'Dimensiunea totală maximă admisă este {filesize}',
}

export default class FileUploader {
  constructor ( element, options ) {
    this.element = element;
    this.options = Object.assign({}, DEFAULT_OPTIONS, options);
    this.options.labelIdle = `${this.options.labelIdle}${this.options.labelIdleSuffix}`

    // Disable submit while uploading
    this.submit = $(this.element).closest('form').find('[type="submit"]');
    this.options.onaddfilestart = this.isLoadingCheck.bind(this);
    this.options.onprocessfiles = this.isLoadingCheck.bind(this);
    this.options.onremovefile = this.isLoadingCheck.bind(this);

    this.initialize();
  }

  initialize() {
    registerPlugin(FilePondPluginImagePreview);
    registerPlugin(FilePondPluginFileValidateSize);
    this.filepond = new FilePond.create(this.element, this.options);
  }

  /**
   * Checks whether all files have been uploaded and enables / disables the submit button accordingly.
   */
  isLoadingCheck(){
    // 5 = PROCESSING_COMPLETE
    var isLoading = this.filepond.getFiles().filter(x=>x.status !== 5).length !== 0;
    if ( isLoading ) {
        this.submit.attr("disabled", "disabled");
    } else {
        this.submit.removeAttr("disabled");
    }
  }
}
